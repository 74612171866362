import type {
  CountriesResponse,
  Country,
  RecaptchaKeyResponse,
} from '../types';

const fetchCountries = async (
  domainAPI: string,
  market: string = '',
  currentLang: string = 'en'
) => {
  try {
    const response = await fetch(
      `${domainAPI}/${currentLang}/api/v3/registration/countries?limit=200&offset=0`
    );

    const {
      result: { node },
    } = (await response.json()) as CountriesResponse;

    const countries = node.map((country: Country) => ({
      code: country.code,
      name: country.name,
      currency: country.currency,
    }));

    const defaultNode = node.find((c) => c.isDefaultCountry === true);
    const marketNode = node.find((c) => c.code === market);
    const userNode = defaultNode || marketNode;
    const defaultCountry = userNode
      ? {
          code: userNode.code,
          name: userNode.name,
          currency: userNode.currency,
        }
      : {};

    return { countries, defaultCountry };
  } catch (error) {
    console.error('Error fetching countries', error);
  }
};

const fetchRecaptchaKey = async (domainAPI: string) => {
  try {
    const response = await fetch(
      `${domainAPI}/en/api/v3/project/info?fields=googleRecaptcha`
    );

    const { result } = (await response.json()) as RecaptchaKeyResponse;

    return result?.googleRecaptcha?.siteKey || '';
  } catch (error) {
    console.error('Error fetching recaptcha key', error);
  }
};

window.onload = async () => {
  const domainAPI = (
    document.querySelector('input[name="apiUrl"]') as HTMLInputElement
  )?.value;
  const market = (
    document.querySelector('input[name="market"]') as HTMLInputElement
  )?.value;
  const currentLang = (
    document.querySelector('input[name="currentLang"]') as HTMLInputElement
  )?.value;

  if (domainAPI) {
    try {
      const recaptchaKey = await fetchRecaptchaKey(domainAPI);

      if (recaptchaKey) {
        window.recaptchaKey = recaptchaKey;

        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`;
        document.body.appendChild(script);
      }
    } catch (error) {
      console.error('Error fetching recaptcha key', error);
    }
  }

  if (domainAPI) {
    const { countries, defaultCountry } =
      (await fetchCountries(domainAPI, market, currentLang)) || {};

    if (!countries || !defaultCountry) {
      return;
    }

    const countrySelect = document.querySelector(
      "select[name='country']"
    ) as HTMLSelectElement;

    if (countrySelect) {
      countries.forEach((country) => {
        const option = document.createElement('option');
        option.value = country.code;
        option.text = country.name;
        countrySelect.appendChild(option);
      });

      countrySelect.value = defaultCountry.code || market;
      setCurrency(defaultCountry);
    }

    countrySelect.addEventListener('change', (event) => {
      const selectedCountry = countries.find(
        (country) => country.code === event.target.value
      );

      if (selectedCountry) {
        setCurrency(selectedCountry);
      }
    });
  }
};

const setCurrency = (country: unknown) => {
  const currencyList = country.currency;
  const currencySelect = document.querySelector(
    "select[name='currency']"
  ) as HTMLSelectElement;

  currencySelect.innerHTML = '';

  currencyList.forEach((curr: unknown) => {
    const currencyOption = document.createElement('option');
    currencyOption.value = curr.code;
    currencyOption.text = curr.name;
    currencySelect.appendChild(currencyOption);
  });
};
