import JustValidate from 'just-validate';
import { sendSignup } from './regFormUtils';
import type { SignupRequest } from '../types';

const SELECTOR_MAP = {
  email: 'input[name="email"]',
  password: 'input[name="password"]',
  country: 'select[name="country"]',
  currency: 'select[name="currency"]',
  acceptTermsConditionsAgeGdpr: 'input[name="acceptTermsConditionsAgeGdpr"]',
  acceptReceivePromotion: 'input[name="acceptReceivePromotion"]',
  formError: 'input[name="formError"]',
};

export const addValidation = () => {
  const validation = new JustValidate('#form', {
    validateBeforeSubmitting: true,
    successFieldCssClass: ['form__control-typ_success'],
    errorFieldCssClass: ['form__control-typ_error'],
    errorLabelCssClass: ['form__label-error'],
    errorLabelStyle: {},
  });

  const translations = {
    ...JSON.parse(document.querySelector('.errors').dataset.message),
  };

  validation
    .addField(SELECTOR_MAP.email, [
      {
        rule: 'required',
        errorMessage: translations['REQUIRED'],
      },
      {
        validator: (value: string) => {
          const emailRegex =
            /^[a-zA-Zа-яА-Я0-9ЁёàáâäãåèąćęłńóśżźéêëìíîïòóôöõøùúûüÿýñçčšžÀÁÂÄÃÅÈÉÊËÌÍÎÏÒÓÔÖÕØÙÚÛÜŸÝÑßÇŒÆČŠŽ∂ð.!#$%&'*+\-\/=?^_`|}{~‘]{1,64}@[a-zA-Zа-яА-Я0-9ЁёàáâäãåèąćęłńóśżźéêëìíîïòóôöõøùúûüÿýñçčšžÀÁÂÄÃÅÈÉÊËÌÍÎÏÒÓÔÖÕØÙÚÛÜŸÝÑßÇŒÆČŠŽ∂ð.!#$%&'*+\-\/=?^_`|}{~‘]{1,255}\.[a-zA-Z]{2,}$/;
          return emailRegex.test(value);
        },
        errorMessage: translations['EMAIL_FORMAT'],
      },
    ])
    .addField(SELECTOR_MAP.password, [
      {
        rule: 'required',
        errorMessage: translations['REQUIRED'],
      },
      {
        validator: (value: string) => {
          const passwordRegex =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\W]{9,99}$/;
          return passwordRegex.test(value);
        },
        errorMessage: translations['WEAK_PASSWORD'],
      },
    ])
    .addField(SELECTOR_MAP.country, [
      {
        rule: 'required',
        errorMessage: translations['REQUIRED'],
      },
    ])
    .addField(SELECTOR_MAP.currency, [
      {
        rule: 'required',
        errorMessage: translations['REQUIRED'],
      },
    ])
    .addField(SELECTOR_MAP.acceptTermsConditionsAgeGdpr, [
      {
        rule: 'required',
        errorMessage: translations['REQUIRED'],
      },
    ])
    .addField(SELECTOR_MAP.formError, [
      {
        validator: () => true,
      },
    ])
    .onSuccess(async (event: SubmitEvent) => {
      await onFormSubmit(event, validation);
    });
};

const onFormSubmit = async (event: SubmitEvent, validation) => {
  const preparedData = getPreparedFormData(event.target);

  toggleFormLock(event.target);
  checkServerValidation(preparedData, validation);
  toggleFormLock(event.target, false);
};

const getPreparedFormData = (form: HTMLFormElement) => {
  const formData = new FormData(form);
  const data = Object.fromEntries(formData.entries()) as Record<string, string>;

  return {
    ...data,
    email: data.email.trim(),
    acceptTermsConditionsAgeGdpr: data.acceptTermsConditionsAgeGdpr === 'on',
    acceptReceivePromotion: data.acceptReceivePromotion === 'on',
  };
};

const toggleFormLock = (form: HTMLFormElement, disabled = true) => {
  const btn = form.querySelector('.btn') as HTMLButtonElement;
  btn.disabled = disabled;

  form.querySelectorAll('input, select').forEach((el) => {
    el.disabled = disabled;
  });
};

const checkServerValidation = async (
  {
    brandUrl,
    apiUrl,
    currentLang,
    market,
    formError,
    ...formData
  }: SignupRequest & Record<string, string>,
  validation: unknown
) => {
  const response = await sendSignup(apiUrl, formData);

  const translations = {
    ...JSON.parse(document?.querySelector('.errors').dataset.message),
  };

  if (response.error) {
    const serverErrors = Object.keys(response.error);
    serverErrors.forEach((err) => {
      const field = SELECTOR_MAP[err] || SELECTOR_MAP.formError;

      const errorMessage =
        translations[response.error[err]] ||
        translations['INVALID_CREDENTIALS'];

      validation.showErrors({ [field]: errorMessage });
      console.error(`Server error: ${err} - ${response.error[err]}`);
    });
  } else {
    window.location.href = `${brandUrl}/${currentLang}/?shortRegistrationToken=${response.result.token}`;
  }
};
