import type {
  AffiliateRequest,
  AffiliateResponse,
  SignupRequest,
  SignupResponse,
} from '../types';

const checkAffiliate = async (
  domainAPI: string,
  urlSearch: AffiliateRequest['query']
) => {
  const response: Promise<AffiliateResponse> = await fetch(
    `${domainAPI}/en/api/v3/comrade/resolve`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ query: urlSearch }),
    }
  )
    .then((res) => res.json())
    .catch((error) => console.error('Error:', error));

  const { result } = await response;

  return result;
};

export const sendSignup = async (
  domainAPI: string,
  formData: SignupRequest
): Promise<SignupResponse> => {
  const urlSearch = Object.fromEntries(
    new URLSearchParams(window.location.search)
  ) as AffiliateRequest['query'];

  const affiliate = await checkAffiliate(domainAPI, urlSearch);

  let grecaptchaToken = '';
  if (window.recaptchaKey) {
    try {
      grecaptchaToken = await (window as any).grecaptcha.execute(
        window.recaptchaKey,
        { action: 'registration/quick/signup' }
      );
    } catch (error) {
      console.error('GrecaptchaKey:', error);
    }
  }

  const signupRequestData = {
    affiliate: {
      data: affiliate,
    },
    ...formData,
    autoBonusCode: affiliate?.value,
    [grecaptchaToken && 'g-recaptcha-response']: grecaptchaToken,
  };

  return await fetch(`${domainAPI}/en/api/v3/registration/quick/signup/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(signupRequestData),
  })
    .then(
      (res) => {
        return res.json();
      },
      (rej) => {
        console.log('rej', rej);
        return rej;
      }
    )
    .catch((error) => {
      console.error('Error:', error);
      return error;
    });
};
